import React from 'react';
import CustomForm from '../components/CustomForm';
import ParallaxSection from '../components/ParallaxSection';
import BookingCard from '../components/BookingCard';
import WhatWeOffer from '../components/WhatWeOffer';
import TestimonialSection from '../components/TestimonialSection';

export default function Home() {
  const handleBookNow = () => {
    window.location.href = '/booking-page'; // Example routing action
  };
  return (
    <div className="flex flex-col items-center w-full gap-8 lg:max-w-[80%] xl:max-w-[70%]">
      {/* booking cards container*/}
      <div className="flex flex-col lg:flex-row gap-10 py-16">
        <BookingCard
          imageUrl="/imgs/booking1.webp"
          title="Business Formation"
          description="LLCs, Partnerships, Corporations, S-Corporations, & Non-Profits"
          time="15 min"
          consultationText="Free Consultation"
          onBookNow={handleBookNow}
        />
        <BookingCard
          imageUrl="/imgs/booking2.webp"
          title="Trademark Registration"
          description="LLCs, Partnerships, Corporations, S-Corporations, & Non-Profits"
          time="15 min"
          consultationText="Contact for price"
          onBookNow={handleBookNow}
        />
        <BookingCard
          imageUrl="/imgs/booking3.webp"
          title="Business Credit"
          description="LLCs, Partnerships, Corporations, S-Corporations, & Non-Profits"
          time="15 min"
          consultationText=""
          onBookNow={handleBookNow}
        />
      </div>

      {/* about company */}
      <div className="flex flex-col justify-center bg-black rounded-lg">
        <img
          src="/imgs/about.webp"
          alt="company office"
          className="rounded-t-lg"
        />
        <div className="flex flex-col justify-center items-center w-full rounded-lg">
          {/* company name */}
          <div className="flex flex-col justify-center items-center text-white p-4 pt-10 gap-2">
            <h2 className="text-4xl font-bold bg-black">
              About Fortune IP Group
            </h2>
            <p className="text-lgs">Business Formation + IP Protection</p>
          </div>
          <div className="flex flex-col gap-4 text-center text-white text-xl py-6 px-2 pb-16 lg:max-w-[80%] xl:max-w-[70%]">
            <p>
              Fortune IP Group is a lawyer led Legal Services Company
              specializing in Nationwide Business Formation & Intellectual
              Property (IP). We start businesses, end them, and handle
              everything in between including dissolution and annual reports.
              Our state-of-the-art IP document filing system can handle any type
              of IP application. Constant human contact. Swift effective
              business & legal solutions.
            </p>
            <p>
              Our services are simple, efficient, and effective and may be
              purchased together or sold separately. These services include
              business formation for Limited Liability Companies, Partnerships,
              & Corporations. Our IP Document filing consists of trademark,
              copyright, and patent filings with the United States Patent and
              Trademark Office or U.S. Copyright Office. Our offerings also
              include IP licensing strategies and audits, which are specifically
              tailored to each client's needs. We pride ourselves on offering
              unbundled business and legal services with a results-based
              approach that meets the needs of business clients in the 21st
              century.
            </p>
          </div>
        </div>
      </div>

      {/* what the company offers */}
      <WhatWeOffer />

      {/* services offered */}
      <div
        className="relative text-white rounded-lg"
        style={{
          backgroundImage: `url('/imgs/services.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Semi-transparent Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-75 rounded-lg"></div>
        <div className="relative p-8 pb-20 flex flex-col justify-center items-center">
          <h2 className="text-4xl font-bold py-10">Services Offered</h2>
          <div className="flex flex-col gap-6 lg:max-w-[80%] xl:max-w-[70%]">
            {/* Business Formation Section */}
            <div className="flex flex-col gap-2">
              <h3 className="text-3xl">Business Formation</h3>
              <p>
                Statewide Business Search, Articles of Incorporation/Formation,
                EIN Number, Operating Agreement & Applicable Business Licenses
              </p>
              <p>
                Types include: Limited Liability Companies, Partnerships,
                S-Corporations, & Non-Profits
              </p>
              <p>Packages:</p>
              <ol>
                <li>Bronze (Essentials)</li>
                <li>Complete (Liability Protection)</li>
                <li>Gold (Business Credit Counseling)</li>
              </ol>
            </div>
            {/* Intellectual Property Section */}
            <div className="flex flex-col gap-2">
              <h3 className="text-3xl">Intellectual Property</h3>
              <p>
                Fortune IP Group offers intellectual property services for
                trademarks, copyrights, and patents. Our services include
                trademark searches, trademark applications, copyright
                applications, patent searches, and patent applications. We also
                provide licensing agreements and IP audits.
              </p>
              <h4 className="text-2xl mt-4">U.S. Trademark Registration</h4>
              <p>
                Search & Clearance with Document Filing. The below services may
                be packaged or sold separately:
              </p>
              <ul>
                <li>Complete Search</li>
                <li>Office Action Responses</li>
                <li>Statement of Use</li>
                <li>Trademark Renewal</li>
              </ul>
              <h4 className="text-2xl mt-4">U.S. Copyright Registration</h4>
              <p>Search & Clearance with Document Filing.</p>
              <h4 className="text-2xl mt-4">U.S. Provisional Patent Filing</h4>
              <p>
                Marketability Report, Search & Clearance, and Document Filing.
              </p>
              <h4 className="text-2xl mt-4">Intellectual Property Audit</h4>
              <p>Comprehensive Review and Report of Business' IP capital.</p>
              <h4 className="text-2xl mt-4">Portfolio Monitoring</h4>
              <p>
                Monthly reports produced to review & monitor active trademarks,
                copyrights, or patents for sustainable protection.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* contact form */}
      <div>
        <CustomForm apiUrl={''} />
      </div>

      {/* testimonial */}
      <ParallaxSection />

      {/* Testimonial Section */}
      <TestimonialSection />
    </div>
  );
}
