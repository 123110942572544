import React from 'react';

export default function PayForService() {
  const services = [
    {
      title: 'Essentials LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Complete LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Gold LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Business Credit Pathway (Standard)',
      url: 'https://square.link/u/ZviS2848',
    },
    {
      title: 'Business Credit Pathway (Premier)',
      url: 'https://square.link/u/ArUmnZ8j',
    },
    {
      title: 'Trademark Registration',
      url: 'https://square.link/u/WDihc5CD',
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center my-4">Client Services</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {services.map((service, index) => (
          <a
            key={index}
            href={service.url}
            className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center justify-center hover:bg-gray-100 transition"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 className="font-semibold text-lg">{service.title}</h2>
            <p className="text-sm text-gray-600 mt-2">Secure Checkout</p>
          </a>
        ))}
      </div>
    </div>
  );
}
