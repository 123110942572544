import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const TestimonialCard = ({ children }: any) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, translateY: 50 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="max-w-sm rounded overflow-hidden shadow-lg"
    >
      <div className="px-6 py-4 flex flex-col items-center justify-between gap-4 min-h-full">
        {children}
      </div>
    </motion.div>
  );
};

const TestimonialSection = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center p-4">
      <div className="space-y-8 max-w-4xl flex flex-col lg:flex-row items-baseline gap-8">
        <TestimonialCard>
          <p className="text-gray-700 text-base">
            "I use Fortune IP Group for all my intellectual property needs
            including which IP to license, how to apply for applications, and
            how to maximize profits on each of my inventions. Each and every
            time, Fortune IP has worked diligently and in a timely manner all
            while maintaining the privacy of my IP. Two successful trademarks
            later and I wouldn't use any other IP service!"
          </p>
          <p className="font-bold text-xl mb-2 lg:text-sm">
            — Parth Patel, Inventor & Entrepreneur
          </p>
        </TestimonialCard>

        <TestimonialCard>
          <p className="text-gray-700 text-base">
            "I have been working with the Fortune IP Group for about five years
            now and they do a great job of managing the risks at each and every
            one of my security sites whether it be through making sure I have
            the right insurance policy or terms of art in contracts. With
            Fortune IP Group I know my security firm is in great hands!"
          </p>
          <p className="font-bold text-xl mb-2 lg:text-sm">
            — Joshua Steeley, Aziel Protection Owner & Head Tactical Officer
          </p>
        </TestimonialCard>

        <TestimonialCard>
          <p className="text-gray-700 text-base">
            "I used Fortune IP Group for help with business dissolution and a
            couple of amendments for another business that I own. Not only were
            they professional and responsive, but they also provided me with the
            correct information and strategy to help me avoid many pitfalls new
            businesses run into. I look forward to working with Fortune IP Group
            for all of my intellectual property needs."
          </p>
          <p className="font-bold text-xl mb-2 lg:text-sm">
            — Victor Spaulding, Certified Personal Trainer & Fitness Studio
            Owner
          </p>
        </TestimonialCard>
      </div>
    </div>
  );
};

export default TestimonialSection;
