import React, { useEffect, useState } from 'react';

const ParallaxSection = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className="relative text-white w-full flex justify-center items-center rounded-lg"
      style={{
        backgroundImage: `url('/imgs/testimonial.jpg')`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed', // this helps achieve the parallax by fixing the background
        backgroundPosition: `center ${offsetY * 0.24}px`, // Adjusts the speed of the parallax effect
      }}
    >
      {/* Semi-transparent Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-75 rounded-lg"></div>
      <div className="relative p-8">
        <h2 className="text-4xl font-bold py-28">Testimonial</h2>
      </div>
    </div>
  );
};

export default ParallaxSection;
